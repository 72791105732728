import React from "react";
import {
  Card,
  
  CardBody,

  Container,
  Row,
  Col,
  Badge
  
} from "reactstrap";

//import { Link } from "react-router-dom";

let FrontEnd = (
  <Col lg={4} className="mt-4 pt-2">
    <Card className="align-items-center">
      <CardBody>
      <Row className="justify-content-center h4 mb-2">FrontEnd</Row>

        <Row className="justify-content-center h6 mb-2">React Ecosystem</Row>
        <Row className="mt-3 justify-content-center">
          <Badge className="m-1" color="secondary" pill>
            React
          </Badge>
          
          <Badge className="m-1" color="secondary" pill>
            Redux
          </Badge>
          
          <Badge className="m-1" color="secondary" pill>
            ReactNative
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Mobex 
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Webpack 
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Npm Scripts
          </Badge>
          
          <Badge className="m-1" color="secondary" pill>
            Hooks
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            axios
          </Badge>
        </Row>


        <Row className="justify-content-center h6 m-3">Styling </Row>
        <Row className="mt-3 justify-content-center">
          <Badge className="m-1" color="secondary" pill>
            Sass/SCSS
          </Badge>
          
          <Badge className="m-1" color="secondary" pill>
            Bootstrap
          </Badge>
          
          <Badge className="m-1" color="secondary" pill>
            ReactStrap
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            MaterialUI
          </Badge>
          
        </Row>
      </CardBody>
    </Card>
  </Col>
);





let MlAi = (
  <Col lg={4} className="mt-4 pt-2">
    <Card className="align-items-center">
      <CardBody>
      <Row className="justify-content-center h4 mb-2">ML & AI</Row>
      <Row className="justify-content-center h6 m-3"> ML Techniques </Row>
        <Row className="mt-3 justify-content-center">
          <Badge className="m-1" color="secondary" pill>
            Supervised and Unsupervised ML
          </Badge>
          
          <Badge className="m-1" color="secondary" pill>
            Tabular Data Analysys
          </Badge>
          
          <Badge className="m-1" color="secondary" pill>
            NLP
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Computer Vision
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Data Engineering
          </Badge>
          
          
        </Row>

        <Row className="justify-content-center h6 m-3">Language Frameworks </Row>
        <Row className="mt-3 justify-content-center">
          <Badge className="m-1" color="secondary" pill>
            Sklearn
          </Badge>
          
          <Badge className="m-1" color="secondary" pill>
            TensorFlow
          </Badge>
          
          <Badge className="m-1" color="secondary" pill>
            Pytorch
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Pandas
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Jupyter notebook
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Pyspark
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            sparksql
          </Badge>
          
          
        </Row>
      </CardBody>
    </Card>
  </Col>
);






let BackEnd = (
  <Col lg={4} className="mt-4 pt-2">
    <Card className="align-items-center">
      <CardBody>
        <Row className="justify-content-center h4 mb-2">BackEnd, DB, Cloud-AI</Row>
        <Row className="mt-3 justify-content-center">
        <Badge className="m-1" color="secondary" pill>
            Django
          </Badge>
          
          <Badge className="m-1" color="secondary" pill>
            Firebase
          </Badge>
          
          <Badge className="m-1" color="secondary" pill>
            Nodes
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            AWS Lambda
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            AWS Sagemaker
          </Badge>
          <Badge className="m-1" color="secondary" pill>
           Azure Databricks
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            GCP
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Postgres
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            NoSql
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Cassendra
          </Badge>
          
        </Row>
      </CardBody>
    </Card>
  </Col>
);


const CustomCard = (props) => {
  //create a local state where all the tags are in an array.

  return (
    <React.Fragment>
      <Container className="mt-40">
        <Row className="justify-content-center mt-5">
          <Col xs={12}>
            <div className="section-title mb-4 mb-md-3 text-center">
              <h3 className="title text-uppercase font-weight-bold">
                My Skills
              </h3>
            </div>
            {/* <Badge className="mr-2" color="primary" pill>
            Personal Favourite
          </Badge> */}
          </Col>
        </Row>
        <Row className="align-items-baseline">
           {FrontEnd}
          {MlAi}
          {BackEnd}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default CustomCard;
