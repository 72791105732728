import React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import Index from '../Container/Portfolio/index'

 function Navigation() {
    return (
        <React.Fragment>
            <BrowserRouter>
            <Switch>
            <Route exact path = "/" component = {Index}/>
                <Route exact path = "/github" render = {() => (window.location = "https://github.com/arjdesign" )}/>
                
                <Route exact path = "/linkedin" render = {() => (window.location = "https://www.linkedin.com/in/quantumbits/" )}/>
                <Route exact path = "/ceetle" render = {() => (window.location = "https://www.ceetleeducation.com/login" )}/>
                <Route exact path = "/quizapp" render = {() => (window.location = "https://play.google.com/store/apps/details?id=com.ceetleapp" )}/>
                <Route path ="/cityhealth" render = {()=> (window.location = "http://www.cityhealth.ai/")}/>
                <Route exact path = "/book" render = {() => (window.location = "https://www.amazon.com/Leaders-Society-social-circumstances-leaders-ebook/dp/B079FR16KF/ref=sr_1_1?dchild=1&keywords=leaders+and+society+arjun+subedi&qid=1594236565&sr=8-1" )}/>
            </Switch>
            </BrowserRouter>
            
        </React.Fragment>
    )
}

export default Navigation
