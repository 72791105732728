import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import images
import book1 from "../../images/book.jpg";

class UpComing extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-primary mt-100 mt-60">
          <Container className="position-relative">
            <Row>
              <Col lg={5} md={6}>
                <div className="app-subscribe text-center text-md-left">
                  <img src={book1} className="img-fluid" alt="Book" />
                </div>
              </Col>

              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-center text-md-left">
                  <p className="font-weight-bold text-white title-dark mb-3">
                    P.S. I have also written a book on leadership.
                  </p>

                  <h2 className="font-weight-bold text-white title-dark mb-3">
                    Leaders and Society:
                    <h3>How social circumstances create leaders</h3>
                  </h2>
                  <div className="mt-4 pt-2">
                    <Link
                      to="/book"
                      className="btn btn-warning"
                    >
                      Amazon Store
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default UpComing;
