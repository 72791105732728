import React, { Component } from "react";


import TemplateDemo from "./TemplatesDemo";
//Import Components

import AboutAuthor from "./AboutAuthor";

import UpComing from "./UpComing";

import CardWithOutImage from "../../Components/CardWithoutImage";
import Topbar from '../Portfolio/TopBar'
//import TopBarFc from './TopBarFc'

import "../../Apps.scss"
import "../../css/materialdesignicons.min.css";
import "../../css/colors/default.css";

export default class Index extends Component {
  componentDidMount() {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  render() {
    return (
      <React.Fragment>
        
        <Topbar/>
        <AboutAuthor className="mt-40 mt-60" />
        <section className="section">
          <CardWithOutImage />
          <TemplateDemo />
          

          
          
        </section>

        <UpComing />
      </React.Fragment>
    );
  }
}


