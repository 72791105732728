// React basic and bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Badge} from "reactstrap";

const cityHealth =
  "https://s3.us-east-2.amazonaws.com/dataowner.ai/CityHealth.png";
const dataowners =
  "https://subediaarjun.s3.us-east-2.amazonaws.com/ceetlequizsite.png";
const ceetle = "https://subediaarjun.s3.us-east-2.amazonaws.com/Screenshot_1600186984.png";

class TemplateDemo extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title mb-4 mb-md-5 text-center">
                <h3 className="title text-uppercase font-weight-bold">
                  My Projects
                </h3>
              </div>
            </Col>
          </Row>

          <div className="bg-light shadow rounded px-4 py-5 p-md-5">
            <Row>
              
              <Col md={6} xs={12} className="mt-5 mt-sm-0">
                <div className="demos-box text-center">
                  <Link to="/ceetle" target="_blank">
                    <img
                      src={dataowners}
                      className="img-fluid rounded"
                      alt="demo-img"
                    />
                     <h5 className="title text-uppercase mt-4 mb-0">
                  ceetleeducation.com{" "}
                    {/* <span className="py-1 px-2 rounded-pill bg-danger text-white h6">
                      View
                    </span> */}
                  </h5> <br/> <p> An online education quiz platform for highschool students.</p> 
                  </Link>
                  <Badge className="m-1" color="primary" pill>
            React
          </Badge>
          <Badge className="m-1" color="primary" pill>
            Material Design
          </Badge>
          <Badge className="m-1" color="primary" pill>
            React Hooks
          </Badge>
          <Badge className="m-1" color="primary" pill>
            Mobex
          </Badge>
          <Badge className="m-1" color="primary" pill>
            Firebase
          </Badge>
                 
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="demos-box text-center">
                  <Link to="/cityhealth" target="_blank">
                    <img
                      src={cityHealth}
                      
                      className="img-fluid rounded"
                      alt="demo-img"
                    />
                    <h5 className="title text-uppercase mt-4 mb-0"> 
                    CityHealth.ai </h5> <br/> <p> Powered by an Array of things (AOT) data initiative of Argonne Lab & University of Chicago {" "} </p> 
                    {/* <span className="py-1 px-2 rounded-pill bg-danger text-white h6">
                      view
                    </span> */}
                       <Badge className="m-1" color="primary" pill>
            React
          </Badge>
          <Badge className="m-1" color="primary" pill>
            Reactstrap
          </Badge>
          <Badge className="m-1" color="primary" pill>
            React-Redux
          </Badge>
          <Badge className="m-1" color="primary" pill>
           Django
          </Badge>
          <Badge className="m-1" color="primary" pill>
            Rest API
          </Badge>
          <Badge className="m-1" color="primary" pill>
            Cassendra
          </Badge>
                  
                  </Link>
                  
                </div>
              </Col>


              <Col md={3} xs={12} className="mt-5  ">
                <div className="demos-box text-center">
                  <Link to="/quizapp" target="_blank">
                    <img
                      src={ceetle}
                      className="img-fluid rounded"
                      alt="demo-img"
                    />
                     <h5 className="title text-uppercase mt-4 mb-0">
                    Quiz APP{" "}
                    {/* <span className="py-1 px-2 rounded-pill bg-danger text-white h6">
                      View
                    </span> */}
                  </h5> <br/> <p> Interactive quiz app for students.</p> 
                  </Link>
                  <Badge className="m-1" color="primary" pill>
            ReactNative
          </Badge>
          <Badge className="m-1" color="primary" pill>
            Material Design
          </Badge>
          <Badge className="m-1" color="primary" pill>
            React Hooks
          </Badge>
          <Badge className="m-1" color="primary" pill>
            Mobex
          </Badge>
          <Badge className="m-1" color="primary" pill>
            Firebase
          </Badge>
                 
                </div>
              </Col>

              {/* <Col md={6} xs={9} className="ml-30 m-5 mr-50">
                
                  <Card className="align-items-center ml-5" >
                    <CardBody>
                    <Row className="justify-content-center h4 mb-2">Technologies Used</Row>
                  <Badge className="m-1" color="primary" pill>
            React
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Redux
          </Badge>
          
          <Badge className="m-1" color="primary" pill>
            ReactNative
          </Badge>
          <Badge className="m-1" color="primary" pill>
            Mobex 
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Webpack 
          </Badge>
          <Badge className="m-1" color="secondary" pill>
            Npm Scripts
          </Badge>
          
          <Badge className="m-1" color="primary" pill>
            Hooks
          </Badge>
          <Badge className="m-1" color="primary" pill>
            axios
          </Badge>


                    </CardBody>
                  
          
                  </Card>
                
              </Col> */}


              
              
            </Row>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default TemplateDemo;
