import React, { Component } from "react";
import { Container, Row, Col} from "reactstrap";
import { Link } from "react-router-dom";

import FeatherIcon from "feather-icons-react";

//Import Images
import auther from "../../images/auther.jpg";
import client05 from "../../images/arjun.jpg";


class AboutAuthor extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <Row className="justify-content-center mt-5">
          <Col xs={12}>
            <div className="section-title mb-4 mb-md-3 text-center">
              <h3 className="title text-uppercase font-weight-bold">
                About Me
              </h3>
            </div>
          </Col>
        </Row> */}
        <Container fluid className="mt-100 mt-50">
          <div
            className="bg-light py-5 rounded shadow"
            style={{ background: `url(${auther}) center center` }}
          >
            <Container>
              <Row className="align-items-center">
                <Col lg={6} md={6}>
                  <div className="section-title text-center">
                    <img
                      src={client05}
                      className="img-fluid rounded-circle shadow-lg"
                      alt="ImageOfArjun"
                    />
                  </div>
                </Col>

                <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                  <div className="ml-md-5">
                    <div>
                      <p className="text-muted">
                       When I was a kid I had a nickname "satu". Contact me
                       if you want to know the reason behind it. :)

                      </p>
                      <p className="text-muted">
                        
                        I started my journey with <strong>Data Science </strong> and <strong> AI/ML</strong>. In last couple of years, I have also jumped in the 
                        field of web development using <strong> REACT </strong>  and its ecosystem.
                        <br/>
                        <br/>
                        Another area I would like to jump in the future would be  Quantum Computing. In my spare time, I read a lot about it. If you are interested in quantuam computing, I would recommend you
                        <a href="https://www.amazon.com/Programming-Quantum-Computers-Essential-Algorithms/dp/1492039683" rel="noopener noreferrer" target="_blank"> this </a> book.

                
        
                      </p>

                      <h5>Arjun Subedi</h5>

                      <ul className="list-unstyled social-icon mb-0 mt-3">
                       
                        <li className="list-inline-item mr-1">
                          <Link to="/github" target = "_blank" rel="noopener noreferrer" className="rounded">
                            <i>
                              <FeatherIcon
                                icon="github"
                                className="fea icon-sm fea-social"
                              />
                            </i>
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to="linkedin/" target = "_blank" rel="noopener noreferrer" className="rounded">
                            <i>
                              <FeatherIcon
                                icon="linkedin"
                                className="fea icon-sm fea-social"
                              />
                            </i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default AboutAuthor;
