import React from 'react';
import Navigation from './Navigation/navigator'

import './App.css';

function App() {
  return (
    <div className="App">
      <Navigation/>
     
    </div>
  );
}

export default App;
